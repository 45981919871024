import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "store/rtkquery/base";

export const coursesessions = createApi({
  reducerPath: "coursesession",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getAllCourses: builder.query({
      query: () => ({
        url: "/curriculum/course",
      }),
    }),
    getCourseSessions: builder.query({
      query: ({ courseId }) => ({
        url: `/curriculum/course/${courseId}`,
      }),
    }),
    getRuleTags: builder.query({
      query: () => ({
        url: "/master/custom/curriculum/rule-tags",
      }),
    }),
    addCourseSession: builder.query({
      query: ({ data, courseSessionId }) => ({
        url: `/curriculum/course-session/${courseSessionId || ""}`,
        method: courseSessionId ? "PUT" : "POST",
        data,
      }),
    }),
    getParticularCourseSession: builder.query({
      query: ({ coursesessionId }) => ({
        url: `/curriculum/course-session/${coursesessionId}`,
      }),
    }),
    addCourseContent: builder.mutation({
      query: ({ data, contentId }) => ({
        url: `/curriculum/content/${contentId || ""}`,
        method: contentId ? "PUT" : "POST",
        data,
      }),
    }),
    addSessionContent: builder.mutation({
      query: ({ data, sessionContentId }) => ({
        url: `/curriculum/course-session/content/${sessionContentId || ""}`,
        method: sessionContentId ? "PUT" : "POST",
        data,
      }),
    }),
    bulkUploadCourseSession: builder.mutation({
      query: ({ data }) => ({
        url: "/curriculum/course-session/bulk-upload",
        method: "POST",
        data,
      }),
    }),
    clearCourseSessionCache: builder.mutation({
      query: () => ({
        url: "/curriculum/course-session/cache-reset",
        method: "POST",
      }),
    }),
  }),
});

export const {
  useLazyGetCourseSessionsQuery,
  useLazyGetParticularCourseSessionQuery,
  useGetAllCoursesQuery,
  useLazyAddCourseSessionQuery,
  useAddCourseContentMutation,
  useAddSessionContentMutation,
  useLazyGetAllCoursesQuery,
  useBulkUploadCourseSessionMutation,
  useClearCourseSessionCacheMutation,
  useGetRuleTagsQuery,
} = coursesessions;
